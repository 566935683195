.ImgWrap {
    width: 100%;
    /* max-width: 80%; */
    display: flex;
    margin: 0 auto;
    flex-direction: column;
}

.ImgWrap.stretched {
    max-width: 100%;
    display: block;

}

.img {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
}

.figure {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0.4em;
    overflow: hidden;
    border: none;
    width: 100%;
}

.figure.border {
    border: 1px solid #e8e8eb
}

.figure.caption {
    margin-bottom: 5px;
}

.figure.Background {
    padding: 15px;
    background: #cdd1e0;
}

.figure.stretched {
    /* max-width: 80%; */
    /* margin: 0 auto; */
}

.figure img {
    border-radius: 3px;
    overflow: hidden;
}

.figure.Background img {
    max-width: 60%;
    margin: 0 auto;
}





.figcaption {
    /* position: absolute; */
    text-align: center;
    bottom: 1px;
    padding: 5px 10px;
    font-size: 16px;
    background-color: rgb(255 255 255);
    color: white;
    border-radius: 2px;
    cursor: default;
    color: #333;
}

figure.Background figcaption {
    bottom: 0px;
}