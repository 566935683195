.questionAnswer {
    display: flex;
    align-items: center;
    width: 100%;
}

.questionAnswer input {
    width: min-content;
    margin-right: 10px;
    /* cursor: pointer; */
    
}

.questionAnswer input[type="text"] {
    width: 100%;
    margin-right: 10px;
    /* cursor: pointer; */

}

.questionAnswerImg {
    display: flex;
    align-items: flex-start;
    width: 100%;
    justify-content: flex-start;
    /* padding-left: 10px; */
}

.questionAnswerImg label {
    /* width: 100%; */
    display: flex;
    flex-direction: column;
    gap: 20px;

}

/* .questionAnswerImg label svg {
    font-size: 18px;

} */

.questionAnswerImg label p {
    cursor: pointer;
    width: 100%;
    display: flex;
    gap: 10px;
    justify-content: flex-start;
}

.questionAnswerImg input {
    display: none;
    width: min-content;
    margin-right: 10px;
}

.QuestionImg {
    max-width: 400px;
    /* max-height: 100px; */
}

.QuestionImg img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}