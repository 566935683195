.optionListItems i {
    cursor: pointer;
}

.sectionListItems a:hover {
    color: #000;
}

.detailInform p:hover {
    color: #0012b3;
}

.sectionListItems.tiles {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: 100%;
    justify-content: flex-start;
    gap: 15px;
    align-items: flex-start;
    padding: 0px;
    width: 100%;
}


.sectionListItems.list {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;
    padding: 0px;
}

.sectionListItems.list .courseCard {
    background-color: #fff;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.sectionListItems.tiles .courseCard {

    width: calc(50% - 8px);
    display: flex;
    justify-content: space-between;
}

.courseCard {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    /* padding: 20px; */
    gap: 20px;

    /* width: 50%; */
    width: 100%;

}

.sectionListItems.tiles .courseCard.default-style {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 20px;
    width: calc(50% - 15px);
    margin-bottom: 30px;
}

.courseCard {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
}

.main {
    width: 100%;
    display: flex;
    height: min-content;
    gap: 20px;
    justify-content: space-between;
}


.courseCard .ImgWrap {
    overflow: hidden;
    display: flex;
    border-radius: 30px;
    max-width: 206px;
    max-height: 162px;
    width: 100%;
}

.courseCard .ImgWrap .Image {
    border-radius: 32px;
    overflow: hidden;
    border-radius: 30px;
    max-width: 100%;
    width: 100%;
    max-height: 100%;
}

.courseCard .ImgWrap .Image img{
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.buyPopup {
    min-width: 600px;
    padding: 32px;
}

.BuyImg {
    border-radius: 16px;
    overflow: hidden;
    display: flex;
    border-radius: 30px;
    max-width: 204px;
    max-height: 186px;
}

.courseCard img,
.BuyImg img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
}

.Content.popup {
    gap: 10px;
}

.popup {
    gap: 10px;
    align-items: stretch;
}

.popup .CourseLabel {
    font-size: 26px;
    font-weight: 600;
}

/* -----------------Content------------------- */

.Content {
    /* max-width: 100%; */
    max-width: -webkit-fill-available;
    ;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;

}

.CourseLabel {
    font-size: 22px;
    font-weight: 700;
    /* 22px */
}

.label {
    font-size: 22px;
    font-weight: 700;
}

.RateArea {
    display: flex;
    gap: 10px;
    align-items: center;

}

.Rate {
    width: 28px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: #FFF;
    border: 2px solid #FCB664;
    font-size: 10px;
}

.Stars {
    display: flex;
    gap: 4px;
}

.BlackStar {
    width: 16px;
    height: 16px;
    fill: #D9D9D9;

}

.YellowStar {
    width: 16px;
    height: 16px;
    fill: #FCB664;

}

.ReviewsCount {
    font-size: 14px;
    font-weight: 400;
}

.EducationArea {
    display: flex;
    width: 100%;
    gap: 20px;
    justify-content: space-between;
    justify-content: space-between;
}

.LessonsCount {
    max-width: 92px;
    width: 100%;
    color: #246FFB;
}

.ClosestStart {
    max-width: 130px;
    width: 100%;
    color: var(--color-red-default);
}

.Duration {
    max-width: 236px;
    width: 100%;
    color: #246FFB;
    ;
}

.Duration.dark {
    color: #163C85;
}


.fullDescription {
    font-size: 16px;
    font-weight: 400;
    word-break: break-word;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.PhotoAndInfo {
    display: flex;
    justify-content: space-between;
}

.courseInformation.full {
    flex-direction: column;
    width: 100%;
    max-width: 365px;
}

.courseInformation.full .courseInformationBlock {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
}

.courseInformation.full .courseInformationBlock p:first-child {
    min-width: 85px;
}

.courseCategories {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 10px;
    width: 100%;
    height: min-content;
}

.courseCategories p {
    font-weight: 600;
    font-size: 10px;
    font-weight: 600;
}

.paymentType {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 4px 8px;
    gap: 10px;

    background: #C1FFA4;
    border-radius: 10px;
}

.additionalInformation p {
    margin-bottom: 0;
}


.educationType {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 4px 8px;
    gap: 10px;

    background: #E2E2E2;
    ;
    border-radius: 10px;
}

.courseName {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px;

    width: 100%;
    height: min-content;
}

.courseName p {
    font-weight: 700;
    font-size: 20px;
}

.briefDescription {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    /* width: 100%; */
    height: min-content;
}

.briefDescription p {
    font-weight: 600;
    font-size: 16px;
}

.additionalInformation {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    gap: 10px;
    width: 100%;
    height: 100%;
}

.Buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.courseOptionsButtons {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;
    position: relative;
}

.pastLessonCourseCard,
.delLessonCourseCard {
    padding: 12px 20px;
    color: #fff;
    border: none;
    border-radius: 8px;
}

.delLessonCourseCard {
    background-color: #d33e3e;
}

.pastLessonCourseCard {
    background-color: #22b936;
}

.courseDuration {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 10px;
    width: max-content;
    height: min-content;
}

.courseDuration p {
    font-weight: 600;
    font-size: 16px;
}

.detailInform {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;

    width: min-content;
    height: min-content;
}

.detailInform p {
    cursor: pointer;
    font-weight: 600;
    font-size: 16px;
    color: #1C56ED;
}

.price {
    font-size: 22px;
    font-weight: 600;
}

.subMenuBtn {
    width: 40px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
}

.byBtn {
    background-color: #fff;
    background: var(--color-red-default);
    padding: 14px 20px;
    border-radius: 11px;
    color: #fff;
    font-size: 16px;
    font-weight: 600;

    /* composes: altColorBackground from 'style.module.css';
    display: flex;
   
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #fff;
    word-wrap: break-word; */
}

.byBtn:disabled {
    background-color: #e4e4e4;
    color: #868686
}

.detailBtn {

    /* border: 2px solid #246FFB; */
    padding: 14px 20px;
    width: 100%;
    border-radius: 11px;
    /* color: #000; */
    dfisplay: flex;
    justify-content: center;
    align-items: center;
    max-height: 44px;

    font-size: 16px;
    font-weight: 600;
    white-space: nowrap;
    min-height: 44px;
    /* display: flex;
    padding: 20px 40px;
    justify-content: center;
    align-items: center;
    width: 100%;
    cursor: pointer;
    color: #fff;
    word-wrap: break-word; */
}

.CardMenu {
    width: 100%;
}

.CardSubModal {
    right: 20px;
    position: absolute;
    display: flex;
    gap: 15px;
}

.BuyAndFavorite {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.BuyAndFavorite {}

.favorite {
    width: 30px;
}

.favorite svg {
    cursor: pointer;
    fill: #fff;
    height: 20px;
    width: 100%;
    stroke: var(--color-red-default);
    stroke-width: 50px;
    transition: all 0.3s linear
}

.favorite svg.active {
    fill: var(--color-red-default);
}

.Buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 200px;
    width: 100%;
}


/* .buttons.full {
    flex-direction: column;
    gap: 20px;
    align-items: start;
    width: 200px;
} */

.fullScreenModalSmall {
    width: 200px;
    height: 170px;
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;


}

.agreeButton {
    display: flex;
    justify-content: center;
    padding: 10px;
    align-items: center;
    height: 35px;
    width: 100%;
}