/* .backButton {
    composes: altColorBackground from 'style.module.css';
} */

/* .backButton>button {
    color: #fff;
} */

/* .activeLink {
    composes: backgrondDefaultBlue from 'style.module.css';
    color: #fff;
} */

/* .activeLink:focus {
    color: #fff;
} */

.frame {
    width: 100%;
}

.NavSidebarElem {
    width: 100%;
    height: 100%;
    max-height: 41px;
}


.NavSidebarElem {
    width: 100%;
    height: 44px;
    color: #717171;
    font-size: 16px;
    font-weight: 600;
    line-height: 150%;
    cursor: pointer;
    transition: all 0.3s linear;
}

.NavSidebarElem:hover {
    background-color: rgba(107, 159, 255, 0.85);
    color: var(--bs-link-hover-color);
}




.leftNavigation a {
    display: flex;
    align-items: center;
    justify-content: center;
}

.active {
    background-color: rgba(36, 111, 251, 0.15);
    color: #246FFB;

}

/*  */
.NavSidebarElem.orange {
    overflow: hidden;
    color: #FF7E3E;
    background-color: #fff;
    border-bottom: 1px solid #DADADA;
}

.NavSidebarElem.orange:hover {
    background-color: rgba(255, 126, 62, 0.45);
    color: #FF7E3E;
}

.orange {
    background-color: #FF7E3E;
    color: #fff;
}


.NavSidebarElem.orange:hover button {
    background-color: rgba(255, 126, 62, 0.45);
    color: #fff;
}

/*  */