.block__content{
width: 100%;
padding: 40px 0;
}
.block__content svg {
    width: 20px;
    height: 20px;
}

.Attaches {
    display: flex;
    gap: 13px;
    align-items: center;
    background: #F2F2F2;
    max-width: 360px;
    padding: 15px 20px;
    border-radius: 10px;
    justify-content: space-between;
}

.AttachesFile {
    display: flex;
    gap: 10px;
    /*align-items: center;*/
}

.AttachesFileInfo {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
}

.AttachesTitle {
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
    color: #2D7FFF;
    text-decoration: underline;
}

.AttachesSize {
    color: #8B8B8B;
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
}