.alertBlock {
    margin: 30px 0px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 32px;
    border:2px solid #2D7FFF;
    border-radius: 10px;
}
.alertBlock p {
    width: 100%;
    word-wrap: nowrap;
}
.alertBlock p a {
    color: #2D7FFF;
    text-decoration: underline;
}

.alertBlock__message {
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
}

.alertBlock__title {
    font-size: 28px;
    font-weight:500;
    line-height:36.4px;
}