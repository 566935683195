

.ReviewCard {
    background-color: #eaeef3;
    padding: 20px;
    border-radius: 15px;
    margin-bottom: 15px;

}

.RevieElement {
    border-bottom: 1px solid #cdcdcd;
    padding: 15px;
    /* border-radius: 15px; */
    margin-bottom: 15px;
}

.ReviewHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.ReviewCreatorData {
    display: flex;
    align-items: center;
    gap: 15px;
}

.ReviewsParams {
    display: flex;
    flex-wrap: wrap;
    gap: 0 15px;
}

.ReviewsParamsGrade {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
}

.ReviewsParamsGrade p {
    /* min-width: 210px; */
    font-size: 14px;
    margin-right: 10px;
}

.ReviewDataCreation {
    display: inline-block;
    font-size: 13px;
    margin-left: 4px;
    color: #00000094;
    font-size: 12px;
}

.ReviewLikesArea {
    display: flex;
    justify-content: flex-end;
    gap: 15px;
}

.ReviewLikesArea p {
    cursor: pointer;
}

.CreatorCommentArea p {
    margin-bottom: 5px;
    font-size: 13px;
    /* font-weight: 500; */
}

.ReviewTextarea {
    width: 100%;
    margin-bottom: 10px;
}

.GreyStar {
    color: #969696
}

.StartsRadioInput label {
    cursor: pointer;
}

.StarsRadioBtns label:hover svg {
    color: #fcd02f
}

.YellowStar label svg,
.YellowStar {
    color: #fcd02f;
    filter: drop-shadow(1px 1px 2px rgb(0 0 0 / 0.4));
}

/* .ReviewBottom{
    display: flex;
    justify-content: space-between;   
} */

