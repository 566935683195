.overlayPopup {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;

    padding: 36px;
    box-sizing: border-box;

    z-index: 0;

    display: flex;
    justify-content: center;
    align-items: center;
}

.overlay {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    cursor: pointer;
}