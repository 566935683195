    .mainContent {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap: 25px;
        width: 100%;
        justify-content: center;
        max-width: 100%;
        width: 100%;
    }

    .orange {
        max-width: 150px;
        width: 100%;
        padding: 10px;
        background-color: var(--alt-color);
        color: #fff;
        transition: all 0.3s linear;
        cursor: pointer;
        border-radius: 14px;
        font-size: 14px;
        font-weight: 700;
    }

    .orange:hover {
        background-color: var(--active-color);
    }

    .orange button {
        color: #fff
    }

    .Back {
        font-weight: 500;
    }

    .PublicCoursSection {
        width: 80%;
        margin: 0 auto;
    }

    .publicCourseInformation {
        display: flex;
        gap: 20px;
        width: 100%;
        justify-content: space-between;
    }

    .CoursDescriptionWrap {
        width: min(80%, 1100px);
    }

    .PublicTextParams {

        max-width: 1100px;
        padding: 30px;
        border-radius: 20px;
        background: #FFF;

        box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.15);

        margin-bottom: 15px;
        width: 100%;
        /* width: min(80%, 1170px); */
    }

    .PublicCourseInput {
        font-size: 27px;
        font-weight: 700;
    }

    .publicСourseImg {
        max-height: 162px;
        width: 100%;
        overflow: hidden;
        /* align-items: center; */
        display: flex;
        justify-content: center;
        border-radius: 15px;
        max-width: 206px;
        border-radius: 32px;

    }

    .publicСourseImg img {
        object-fit: cover;
        height: 100%;
        width: 100%;
    }

    .PublicСourseParams {
        width: -webkit-fill-available;
        display: flex;
        flex-direction: column;
        gap: 14px;
    }

    .PublicСourseParams p {
        display: flex;
        justify-content: space-between;
        color: #000;
        font-size: 14px;
        font-weight: 400;
    }



    .PublicСourseParams {
        font-weight: 400;
    }

    .PublicСourseParams .paramValue {
        font-size: 14px;
        font-weight: 400;
    }

    span.dataLabel {
        color: var(--color-red-default);
        font-size: 16px;
        font-weight: 700;
    }

    span.dataLabel+span.paramValue {
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
    }


    .RateArea {
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        gap: 14px;
        width: 100%;
        align-items: center;
    }

    .Rate {
        width: 28px;
        height: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background: #FFF;
        border: 2px solid #FCB664;
        font-size: 10px;
    }

    .Rate p {
        font-size: 10px;
    }

    .Stars {
        display: flex;
        gap: 4px;
    }

    .BlackStar {
        width: 16px;
        height: 16px;
        fill: #D9D9D9;

    }

    .YellowStar {
        width: 16px;
        height: 16px;
        fill: #FCB664;

    }

    .ReviewsCount {
        font-size: 14px;
        font-weight: 400;
    }

    .MainContent {
        max-width: 835px;
    }


    /* Текстовые параметры */
    .MainBlockText {
        max-width: 100%;
        width: 100%;
        display: flex;
        gap: 20px;
        /* flex-direction: column; */
        justify-content: space-between;
    }

    .CoursManageTabsBtns {
        display: flex;
        gap: 10px;
        justify-content: space-between;
        margin-bottom: 15px;
    }

    .PublicCoursDescription {
        display: flex;
        flex-direction: column;
        gap: 10px;
        font-size: 14px;
        font-weight: 400;
    }

    /* ------------- данные об уроках -------------*/

    .InformationBlock {
        margin-bottom: 20px;
        display: flex;
        padding: 30px;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        border-radius: 20px;
        background: #FFF;
        box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.15);
        align-items: flex-start;
    }

    .rightButtonsSection {
        max-width: 220px;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 14px;
        align-items: center;
    }

    .PriceAndFavor {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
    }

    .price {
        font-size: 26px;
        font-style: normal;
        font-weight: 700;
    }

    .favorite {
        width: 30px;
    }

    .favorite svg {
        cursor: pointer;
        fill: #fff;
        height: 20px;
        width: 100%;
        stroke: var(--color-red-default);
        stroke-width: 50px;
        transition: all 0.3s linear
    }

    .favorite svg.active {
        fill: var(--color-red-default);
    }



    .buyBtn {
        background: var(--color-red-default);
        padding: 10px 20px;
        color: #fff;
        font-weight: 600;
        width: 100%;
    }

    .disabled {
        cursor: default;
        background-color: #e4e4e4;
        color: #868686
    }

    /* -------------Картчка создателя курса--------------- */

    .anyCoursesWrap {
        display: flex;
        gap: 20px;
        /* max-height: 150px; */
        flex-wrap: wrap;
        /* overflow-y: hidden; */
    }

    .anyCoursesWrap h3 {
        font-weight: 600;
    }

    .anyCoursesWrap p {
        font-size: 13px;
    }


    /* ---------------------------------------------- */

    .detailCenter {
        display: flex;
        flex-direction: row;
        gap: 10px;
        max-width: 100%;
    }

    .courseInformation {
        padding: 0;
    }

    .buyCourse {
        background: #07c111;
        padding: 15px;
    }

    .ratingTeacherCard,
    .sectionTestMaterial {
        display: flex;
        gap: 10px;
        flex-direction: column;
    }

    .previewVideo {
        padding: 0;
    }

    .previewVideo iframe {
        width: 100%;
        border-radius: var(--default-style-border-rad);
    }

    /* --------------------description------------------- */
    .description {
        width: 100%;
        text-align: left;
        display: flex;
        flex-direction: column;
        display: flex;
        flex-direction: column;
        gap: 10px;
        font-size: 18px;
        font-weight: 600;
        word-wrap: break-word
    }

    .DescriptionText {
        font-size: 16px;
        font-weight: 400;
        word-wrap: break-word
    }

    .creator {
        font-size: 20px;
        font-weight: 600;
    }

    .CreatorCard {
        display: flex;
        justify-content: space-between;
        gap: 20px;
        width: 100%;
    }

    .CreatorAvatar {
        display: flex;
        max-width: 227px;
        border-radius: 26px;
        overflow: hidden;
        aspect-ratio: 1;
    }

    .CreatorAvatar img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .CreatorInformation {
        display: flex;
        flex-direction: row;
        gap: 20px;
        justify-content: flex-start;
        font-size: 20px;
        font-weight: 600;
        width: 100%;
        height: min-content;
        align-items: flex-start;
        justify-content: space-between;

    }

    .content {
        display: flex;
        flex-direction: column;
        gap: 15px;
        width: 100%;
        justify-content: space-between;


        font-size: 14px;
        font-weight: 400;
    }

    .profession {
        width: 100%;
        display: flex;
        gap: 10px;

        color: #000;
    }

    .label {
        font-size: 16px;
        font-weight: 600;
    }

    .goProfile {
        /* padding: 10px 14px;
    border-radius: 10px;
    border: 0.689px solid #246FFB;  
    background: #FFF;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);

    width: fit-content;
    color: #246FFB;
    font-size: 10px;
    font-weight: 700;
    transition: all 0.3s linear; */
        /* 14.467px */
        display: flex;
        padding: 12.533px 17.904px;
        justify-content: center;
        align-items: center;
        gap: 8.952px;
        border-radius: 12.533px;
        border: 0.895px solid #246FFB;

        background: #FFF;

        box-shadow: 0px 0px 4.476px 0px rgba(0, 0, 0, 0.15);

        color: #246FFB;

        text-align: center;
        font-family: Golos Text;
        font-size: 12.533px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        width: fit-content;
        white-space: nowrap;
        /* 18.799px */
    }

    .goProfile:hover {
        background-color: #246FFB;
        color: #fff;
    }

    .WrongProfile {
        font-size: 17px;
        color: #939393;
    }

    .buyPopup {
        min-width: 600px;
        padding: 32px;
    }