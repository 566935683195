.SimpleImg{
    border-radius: 15px;
    overflow: hidden;
    
}

.SimpleImg img{
    background: #fff;
    object-fit: cover;
    width: 100%;
    height: 100%;
}