.defaultWrapper {
    background: var(--default-style-back);
    /* box-shadow: var(--default-style-shadow); */
    border-radius: var(--default-style-border-rad);
    border: 1px solid var(--defaulr-border-color);
    padding: var(--default-style-padding);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    height: min-content;
}

.defaultWrapper > button {
    width: 100%;
    height: 100%;
}

.defaultWrapper > form {
    width: 100%;
}
