p.CustomParagraph {
    line-height: 1.6em;
    outline: none;
    padding: 0.4em 0;
    margin: 0  0 !important;
    /* width: 80%; */
}

h3.CustomHeader3 {
    padding: 0.6em 0 3px;
    margin: 0 !important;
    line-height: 1.25em;
    outline: none;
}

.CustomListContainer {
    padding: 0.4em 0;
    padding-left: 40px;
    list-style: disc !important;
    margin: 0 !important;
}

.CustomListItem {
    padding: 5.5px 0 5.5px 3px;
    line-height: 1.6em;
    margin: 0;
}

.CustomDelimiter{ 
    display: block !important;
    line-height: 1.6em !important;
    width: 100%;
    text-align: center !important;
    padding: 0.4em 0;
}

.CustomDelimiter svg{
    display: none;
}

.CustomDelimiter::before{
    display: inline-block;
    content: "***";
    font-size: 30px;
    line-height: 65px;
    height: 30px;
    letter-spacing: 0.2em;
}

/* AttachmentRender */