
.textareaOutput{
    border: 1px solid rgba(201,201,204,.48);
    -webkit-box-shadow: inset 0 1px 2px 0 rgba(35,44,72,.06);
    box-shadow: inset 0 1px 2px #232c480f;
    border-radius: 3px;
    padding: 10px 12px;
    outline: none;
    width: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    min-height: 200px;
}

