.MainStage {
    display: flex;
    width: 600px;
    max-width: 600px;
    max-height: 520px;
    height: 100%;
    padding: 24px;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    flex-shrink: 0;
    border-radius: 30px;
    border: 1px solid rgba(0, 0, 0, 0.08);
    background: #F8F8F8;
    box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.01);

}

.cardHeader {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    width: 100%;
}

.Stages {
    height: 200px;
    width: 100%;
}

.stageContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
    background-color: #F8F8F8;
}

.StageField {
    width: 100%;
}

.StageField h2 {
    color: #000;

    font-family: Golos Text;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
}

.StageField input+label {
    padding: 10px
}

.StageField input+label span {
    padding: 0 10px
}

.StageField.FullField input+label {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.flexInput {
    display: flex;
    align-items: center;
    gap: 15px;
    width: 100%;
}

.CheckBox {
    justify-content: flex-start;
    align-items: baseline;
    margin-bottom: 10px;
}

.CheckBox input {
    width: 15px;
    height: 15px;
}

.timeInput {
    width: 120px;
}

.newCoursBtnsLeft {
    width: 125px;
}

.newCoursBtnsRight {
    width: 125px;
}

.StartsRadioInput label {
    cursor: pointer;
}

.StarsRadioBtns label:hover svg {
    color: #fcd02f
}

.Radios {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}

.Radios input {
    display: none;
}

.Radios label {
    cursor: pointer;
    padding: 10px;
    border-radius: 13px;
    background: #FFF;
    box-shadow: 0px 3.80042px 15px 0px rgba(0, 0, 0, 0.20);
}

.Radios li {
    display: flex;
    align-items: center;
    flex-direction: row;
    list-style-type: none;
    gap: 20px;

}

.Radios input:checked~label {
    background-color: #246ffb;
    color: #fff
}

.YellowStar label svg,
.YellowStar {
    color: #fcd02f;
    filter: drop-shadow(1px 1px 2px rgb(0 0 0 / 0.4));
}

.successText {
    font-size: 23px;
    font-weight: 600;
    color: #3999aa;
}