.RateArea {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    gap: 14px;
    width: 100%;
    align-items: center;
}

.Rate {
    max-width: 28px;
    width: 100%;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: #FFF;
    border: 2px solid #FCB664;
    font-size: 10px;
}

.Rate p {
    width: min-content;
}

.Stars {
    display: flex;
    gap: 4px;
}

.BlackStar {
    width: 16px;
    height: 16px;
    fill: #D9D9D9;

}

.YellowStar {
    width: 16px;
    height: 16px;
    fill: #FCB664;

}

.ReviewsCount {
    font-size: 14px;
    font-weight: 400;
}