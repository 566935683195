.blockquote {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    line-height: 30px;
    font-size: 20px;
    font-style: normal;
    gap: 10px;
    border-left:2px solid #2D7FFF;
    margin: 20px 0;
    text-align: center;
    padding: 0 32px;
    word-break: break-all;
}
.blockquote__text{
    word-break: break-word;
}