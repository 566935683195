.NestedList {
    margin: 0;
}
.NestedList.unordered  {
    /* position: absolute; */
    padding-left: 25px;
    margin-left: 30px;
    /* left: 0; */
}
.NestedList.ordered {
    list-style: none;
}

.nestedList__item {
    position: relative;
    padding-left:30px;
    padding-top: 14px;
}


.NestedList.unordered  .nestedList__item.unordered  {
    /* position: absolute; */
    padding-left: 0px;
    margin-left: 0px;
    /* left: 0; */
}

.nestedList__item ul.NestedList  {
    padding-left: 0px;
    margin-left: 0px;
}

.nestedList__itemNumber {
    margin-right: 5px;
    line-height: 150%;
}

.nestedList__itemContent {
    word-break: break-word;
    line-height: 150%
}

.nestedList__itemWrapper {
    display: flex;
    line-height: 150%;
}
